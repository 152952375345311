////
/// Fonts
/// A collection of font mixins
/// @since 0.0.1
////
@import '../settings/fonts';
@import '../settings/letter-spacing';
@import '../settings/line-height';
@import '../settings/text';
@import '../settings/weights';

$base-font-size: null !default;
$is-ios: false !default;

// Size deltas in pixels for each font's fallback
//
// @todo Move this somewhere better, like a settings file
$fallback-sizes: (
  garamond: -5,
  atlantic-serif: 0,
  noe: 1,
  lyon: -1,
  druk: -3,
);


@mixin font-fallback($font-name, $family, $fallback, $size: null) {
  @if $size {
    @include font-size($size, $font-name);
  }

  @if $is-ios {
    font-family: $family;
    @content;
  }
  @else {
    font-family: $fallback;

    .no-js &,
    .fonts-loaded & {
      font-family: $family;
      @content;
    }
  }
}

/// Generate the correct font-sizes for using a font & its fallback. This makes
/// it much easier to set responsive font sizes without carrying over all the
/// additional style rules of the individual font mixins.
///
/// @param {Number} $size - The size of the text (must be defined `text` size)
/// @param {String} $family - The font family name
///
/// @example
///   .content {
///     @include noe-size($text-24);
///     // ...
///   }
@mixin font-size($size, $family: null) {
  @if map-has-key($fallback-sizes, $family) and not $is-ios {
    $family: to_lower_case($family);
    $fallbackSize: map-get($fallback-sizes, $family);

    font-size: $size + $fallbackSize;

    .no-js &,
    .fonts-loaded & {
      font-size: $size;
    }
  }
  @else {
    @if $is-ios and $base-font-size {
      font-size: calc(#{$size / $base-font-size * 1rem} * var(--font-scale));
    }
    @else {
      font-size: $size;
    }
  }
}

/// Generate styles for the "Atlantic Serif" font family and its fallback
///
/// @param {Number} $size - The size of the text (must be defined `text` size)
///
/// @example
///   .content {
///     @include atlantic-serif($text-24);
///     // ...
///   }
@mixin atlantic-serif($size: null) {
  font-variant-numeric: lining-nums;
  font-weight: $weight-normal;

  @include font-fallback('atlantic-serif', $font-atlantic, $font-atlantic--default, $size) {
    line-height: $lh-short;
  }
}

/// Generate styles for the "Garamond" font family and its fallback
///
/// @param {Number} $size - The size of the text (must be defined `text` size)
///
/// @example
///   .content {
///     @include garamond($text-24);
///     // ...
///   }
@mixin garamond($size: null) {
  font-variant-numeric: lining-nums;
  font-weight: $weight-normal;
  @include font-fallback('garamond', $font-garamond, $font-garamond--default, $size) {
    line-height: $lh-short;
  }
}

/// Generate styles for the "Noe Display" font family and its fallback
///
/// @param {Number} $size - The size of the text (must be defined `text` size)
///
/// @example
///   .content {
///     @include noe($text-24);
///     // ...
///   }
@mixin noe($size: null) {
  font-variant-numeric: lining-nums;
  font-weight: $weight-black;
  line-height: 1.1;

  @include font-fallback('noe', $font-noe, $font-noe--default, $size) {
    line-height: $lh-short;
  }
}

/// Generate styles for the "Lyon Display" font family and its fallback
///
/// @param {Number} $size - The size of the text (must be defined `text` size)
///
/// @example
///   .content {
///     @include lyon($text-24);
///     // ...
///   }
@mixin lyon($size: null) {
  @include font-fallback('lyon', $font-lyon, $font-lyon--default, $size);
}

/// Generate styles for the "Druk" font family and its fallback
///
/// @param {Number} $size - The size of the text (must be defined `text` size)
///
/// @example
///   .content {
///     @include druk($text-24);
///     // ...
///   }
@mixin druk($size: null) {
  letter-spacing: 0.1rem;
  line-height: 1.3;

  @include font-fallback('druk', $font-druk, $font-druk--default, $size) {
    letter-spacing: $lp-widest;
    line-height: $lh-short;
  }
}

/// Generate styles for the "Graphik" font family and its fallback
///
/// @param {Number} $size - The size of the text (must be defined `text` size)
///
/// @example
///   .content {
///     @include graphik($text-24);
///     // ...
///   }
@mixin graphik($size: null) {
  @include font-fallback('graphik', $font-graphik, $font-graphik--default, $size);
}
