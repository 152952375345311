// $$\   $$\  $$$$$$\     $$$$$\  $$$$$$\
// $$$\  $$ |$$  __$$\    \__$$ |$$  __$$\
// $$$$\ $$ |$$ /  $$ |      $$ |$$ /  \__|
// $$ $$\$$ |$$ |  $$ |      $$ |\$$$$$$\
// $$ \$$$$ |$$ |  $$ |$$\   $$ | \____$$\
// $$ |\$$$ |$$ |  $$ |$$ |  $$ |$$\   $$ |
// $$ | \$$ | $$$$$$  |\$$$$$$  |\$$$$$$  |
// \__|  \__| \______/  \______/  \______/
//
// Because we love our users regardless of their browsing behavior
@import 'components/article-writer';
@import 'components/footer';

.u-dynamic-content__loader {
  display: none;
}
