// Footer
//
// A footer for a document or a section of content.
//
// Markup: components/footer.html
//
// Since: 0.0.2
//
@import 'breakpoint';

@import '../settings/breakpoints';
@import '../settings/colors';
@import '../settings/fonts';
@import '../settings/height';
@import '../settings/letter-spacing';
@import '../settings/line-height';
@import '../settings/sizes';
@import '../settings/space';
@import '../settings/text';
@import '../settings/weights';
@import '../settings/width';

@import '../tools/fonts';
@import '../tools/list-reset';

.c-footer {
  @include graphik($text-16);

  background-color: $color-black;
  color: $color-white;
  font-family: $font-graphik;
  padding: 0 $space-16 $space-32;

  &.lazyloaded {
    opacity: 1;
  }

  @include breakpoint($breakpoint-medium) {
    @include font-size($text-14);

    padding: $space-32 $space-32 $space-48;
  }
}

.c-footer__container {
  margin: 0 auto;
  max-width: $width-article;
}

.c-footer__nav--top {
  margin-bottom: $space-32;

  @include breakpoint($breakpoint-medium) {
    border-bottom: 1px solid $color-white;
    margin-bottom: $space-16;
    padding-bottom: $space-32;
  }
}

.c-footer__nav--bottom {
  @include font-size($text-10);

  letter-spacing: $lp-loose;
}

.c-footer__section {
  border-bottom: 1px solid $color-white;

  @include breakpoint($breakpoint-medium) {
    border-bottom: 0;
  }
}

.c-footer__section-title {
  @include font-size($text-14);

  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: $weight-bold;
  justify-content: space-between;
  letter-spacing: $lp-wide;
  padding: $space-24 0;
  text-transform: uppercase;

  &::after {
    border-color: $color-white;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: '';
    height: $size-8;
    margin-right: $space-4;
    transform: rotateX(0) rotateZ(-45deg);
    transform-origin: center center;
    transition: transform 500ms linear;
    width: $size-8;

    @include breakpoint($breakpoint-medium) {
      content: normal;
    }

    .no-js & {
      transform: rotateX(180deg) rotateZ(-45deg);
    }
  }

  @include breakpoint($breakpoint-medium) {
    @include font-size($text-12);

    cursor: auto;
    margin-bottom: $space-16;
    padding: 0;
  }

  &.is-open {
    &::after {
      transform: rotateX(180deg) rotateZ(-45deg);
      transition: transform 250ms linear;
    }

    & + .c-footer__secondary {
      max-height: $height-footer-secondary;
      transition: max-height 500ms linear;
    }
  }
}

.c-footer__secondary {
  max-height: 0;
  overflow: hidden;
  transition: max-height 250ms linear;

  @include breakpoint($breakpoint-medium) {
    max-height: none;
  }

  .no-js & {
    max-height: none;
  }
}

.c-footer__list {
  @include list-reset;
}

.c-footer__list--top {
  @include breakpoint($breakpoint-medium) {
    display: flex;
    justify-content: space-between;
  }
}

.c-footer__list--secondary {
  margin: 0 $space-16 $space-24;

  @include breakpoint($breakpoint-medium) {
    margin: 0;
  }
}

.c-footer__list--follow {
  display: flex;
  flex-wrap: wrap;
  height: $size-16;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: $space-24;
  }
}

.c-footer__item {
  line-height: $lh-medium;
}

.c-footer__item--top {
  &:not(:last-child) {
    margin-bottom: $space-16;
  }
}

.c-footer__item--bottom {
  display: inline-block;
  margin-bottom: $space-8;
  line-height: $lh-short;
  

  &:not(:last-child) {
    border-right: 1px solid $color-white;
    margin-right: $space-8;
    padding-right: $space-8;
  }
}

.c-footer__item--follow {
  &:not(:last-child) {
    margin-right: $space-24;
  }
}

.c-footer__link {
  color: $color-white;
  fill: $color-white;

  &:hover {
    color: $color-shuttle-gray;
    fill: $color-shuttle-gray;
  }
}

.c-footer__bottom {
  text-align: center;

  @include breakpoint($breakpoint-xlarge) {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
  }
}

.c-footer__bottom-info {
  margin: 0 0 $space-32;

  @include breakpoint($breakpoint-xlarge) {
    margin: 0;
  }
}

.c-footer__copyright {
  @include font-size($text-10);

  margin: 0;
}

.c-footer__logo {
  height: $size-32;
  margin: 0 auto;

  @include breakpoint($breakpoint-xlarge) {
    margin: 0;
  }
}

.c-footer__icon {
  height: $size-16;
  width: $size-16;
}

.c-nav__atlantic-wordmark--lacroix {
  svg {
    fill: $color-white;
  }
}

.c-footer__logo-image {
  fill: $color-white;
  width: 136px; 
  height: 22px;
}
