////
/// Letter Spacing
///
/// The letter spacing scale
///
/// @since 0.0.1
////

/// The thinnest letter spacing
///
/// @type Number
$lp-thinnest: -2px;

/// A thinner letter spacing
///
/// @type Number
$lp-thinner: -1px;

/// Thin letter spacing
///
/// @type Number
$lp-thin: -0.5px;

/// Something between thing and normal
///
/// @type Number
$lp-tight: -0.25px;

/// Reset letter spacing
///
/// @type Number
$lp-normal: 0;

/// Something between normal and wide
///
/// @type Number
$lp-loose: 0.25px;

/// A wide letter spacing
///
/// @type Number
$lp-wide: 0.5px;

/// A semi-wide letter spacing
///
/// @type Number
$lp-semi-wide: 0.75px;

/// A wider letter spacing
///
/// @type Number
$lp-wider: 1px;

/// The widest letter spacing
///
/// @type Number
$lp-widest: 2.25px;

/// 3px
///
/// @type Number
$lp-3: 3px;
