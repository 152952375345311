// Article Writer
//
// The **Article Writer** component is a short write-ups about an individual author and can provide links to their respective social media profiles and/or email.
//
// Markup: components/article-writer.html
//
// Since: 0.0.1
//
// Styleguide: Components.Writer
@import 'breakpoint';

@import '../settings/breakpoints';
@import '../settings/atl-colors';
@import '../settings/fonts';
@import '../settings/line-height';
@import '../settings/letter-spacing';
@import '../settings/sizes';
@import '../settings/space';
@import '../settings/text';
@import '../settings/weights';
@import '../settings/width';

@import '../tools/fonts';
@import '../tools/dotted-line';

.c-article-writer {
  @include graphik($text-18);
  display: flex;
  margin-top: 0;
  position: relative;

  &.lazyloaded {
    opacity: 1;
  }

  & + .c-article-writer {
    margin-top: $space-16;
  }
}

.c-article-writer__image {
  flex-basis: $size-72;
  flex-shrink: 0;
  margin: 0 $space-16 0 0;
  max-width: $size-72;

  @include breakpoint($breakpoint-small) {
    margin-right: $space-24;
    // Add 2px for top & bottom border
    flex-basis: #{$size-100 + 2px};
    max-width: #{$size-100 + 2px};
  }
}

.c-article-writer__media {
  padding-bottom: percentage(1/1);
}

.c-article-writer__picture {
  background-color: $color-smoke--darker;
  border: 1px solid $color-smoke--dark;
  border-radius: 50%;
  box-sizing: border-box;
  height: $size-72;
  overflow: hidden;

  @include breakpoint($breakpoint-small) {
    // Add 2px for top & bottom border
    height: #{$size-100 + 2px};
  }
}

.c-article-writer__img {
  display: block;
  opacity: 0;
  transition: opacity 0.5s;

  &.lazyloaded {
    opacity: 1;
  }

  .no-js & {
    opacity: 1;
  }
}

.c-article-writer__content {
  flex-basis: 100%;
  flex-grow: 1;

  @include breakpoint($breakpoint-small) {
    padding-top: $space-4;
  }
}

.c-article-writer__bio {
  font-family: $font-garamond;
  font-style: italic;
  line-height: $lh-medium;

  // Set child <i> and <em> tags to roman
  em,
  i {
    font-style: normal;
  }

  // Embedded author link
  // This tag is added server-side
  .author-link {
    font-family: $font-graphik;
    font-style: normal;
    font-size: $text-14;
    font-weight: $weight-semi-bold;
    letter-spacing: $lp-wider;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: $color-shuttle-gray;
    }
  }
}

.c-article-writer__connect {
  font-size: $text-16;
  margin: 0 $space-16 0 0;

  @include breakpoint($breakpoint-small) {
    margin-right: $space-32;
  }
}

.c-article-writer__social {
  align-items: center;
  display: flex;
  margin-top: $space-8;

  @include breakpoint($breakpoint-small) {
    border-top: 1px solid $color-smoke--dark;
    margin-top: $space-16;
    padding-top: $space-16;
  }
}

.c-article-writer__social-link {
  align-items: center;
  display: inline-flex;
  fill: $color-black;
  line-height: $lh-shortest;

  &:not(:last-child) {
    margin-right: $space-16;

    @include breakpoint($breakpoint-small) {
      margin-right: $space-32;
    }
  }

  &:hover {
    color: $color-shuttle-gray;
    fill: $color-shuttle-gray;
  }

  .c-article-writer & {
    text-decoration: none;
  }
}

.c-article-writer__social-link-icon {
  height: $size-16;
  width: $size-16;
}


.c-article-writer__social-link-name {
  display: none;
  font-size: $text-14;
  letter-spacing: $lp-loose;
  margin-left: $space-8;

  @include breakpoint($breakpoint-small) {
    display: inline-block;
  }
}
