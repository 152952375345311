/// Light red for _The Atlantic_ otherwise known as "Battlehymn Light"
///
/// @type Color
$color-lavender-blush: #fff4f3;

/// The red for _The Atlantic_ otherwise known as "Battlehymn"
///
/// @type Color
$color-amaranth: #e7131a;

/// Dark red for _The Atlantic_ otherwise known as "Battlehymn Dark"
///
/// @type Color
$color-black-rose: #5a032d;

/// Dark red for _The Atlantic_ otherwise known as "Crimson"
///
/// @type Color
$color-crimson: #cf0e3a;

/// Light turquiose for _The Atlantic_ otherwise known as "Neptune Light"
///
/// @type Color
$color-bubbles:#f1f8f6;

/// The turquiose for _The Atlantic_ otherwise known as "Neptune"
///
/// @type Color
$color-scooter: #31c6cc;

/// A darker turquiose for _The Atlantic_ otherwise known as "Neptune Text"
///
/// @type Color
$color-bondi-blue: #0097ac;

/// The darkest turquiose for _The Atlantic_ otherwise known as "Neptune Dark"
///
/// @type Color
$color-atoll: #0e5d72;

/// Dark green for _The Atlantic_ otherwise known as "Forest Green"
///
/// @type Color
$color-pine-green: #006A79;

/// The color black
///
/// @type Color
$color-black: #000;

/// Otherwise referred to as "Coal"
///
/// @type Color
$color-shark: #20272c;

/// Otherwise referred to as "Slate"
///
/// @type Color
$color-limed-spruce: #39434b;

/// Otherwise referred to as "Granite"
///
/// @type Color
$color-shuttle-gray: #5e6a74;

/// Otherwise referred to as "Silver"
///
/// @type Color
$color-regent-gray: #8592a4;

/// Background color for paywall nudge
///
/// @type Color
$color-gray-nudge: #afb8c6;

/// Background color for paywall nudge
///
/// @type Color
$color-gray-nudge-background: #e0f2fa;

/// Background color for paywall nudge subscribe button
///
/// @type Color
$color-black-nudge-button: #21272c;

/// Color for nudge accent
///
/// @type Color
$color-blue-nudge-button: #0550c8;

/// Text color for paywall nudge green text
///
/// @type Color
$color-green-nudge-text: #005864;

/// Bright blue for paywall nudge elements
///
/// @type Color
$color-paywall-royal-blue: #0550c8;

/// The color white
///
/// @type Color
$color-white: #fff;

/// Light theme; lightest color
///
/// @type Color
$color-snow: #f9fafc;

/// Light theme; mid range color
///
/// @type Color
$color-snow--dark: #eff2f7;

/// Light theme; darkest color
///
/// @type Color
$color-snow--darker: #e7e7e7;

/// Dark theme; lightest color
///
/// @type Color
$color-smoke: #e0e6ed;

/// Dark theme; mid range color
///
/// @type Color
$color-smoke--dark: #d3dce6;

/// Dark theme; darkest color
///
/// @type Color
$color-smoke--darker: #c0ccda;

/// Turbo
///
/// Otherwise known as "Warning"
///
/// @type Color
$color-turbo: #ffe200;

/// Buttermilk
///
/// Otherwise known as "Warning (Lite)"
///
/// @type Color
$color-buttermilk: #fff6b7;

/// Observatory
///
/// Otherwise known as "Success"
///
/// @type Color
$color-observatory: #038768;

/// Aero Blue
///
/// Otherwise known as "Success (Lite)"
///
/// @type Color
$color-aero-blue: #c3ffd9;

/// Meteorite
///
/// Dark blue for sponsored content otherwise known as "Sponsor Dark"
///
/// @type Color
$color-meteorite: #311b73;

/// Royal Blue
///
/// Blue for sponsored content otherwise known as "Sponsor";
///
/// @type Color
$color-royal-blue: #655ade;

/// Melrose
///
/// A lighter blue for sponsored content otherwise known as "Sponsor Light"
///
/// @type Color
$color-melrose: #a299ff;

/// Titan White
///
/// The lightest blue for sponsored content otherwise known as "Sponsor Lightest"
///
/// @type Color
$color-titan-white: #e1e7ff;

/// Facebook
///
/// The Facebook brand blue
///
/// @type Color
$color-facebook: #395185;

/// Facebook
///
/// Hover state for facebook
///
/// @type Color
$color-facebook--dark: #2d4373;

/// Twitter
///
/// The Twitter brand blue
///
/// @type Color
$color-twitter: #1da1f2;

/// Google
///
/// The Google brand blue
///
/// @type Color
$color-google: #4285f4;

/// Google
///
/// Hover state for google
///
/// @type Color
$color-google--dark: #1266f1;


/// Red
///
/// Atlantic red
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-red: #EC1B23;

/// Blue
///
/// Breaking news alert
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-blue: #458CD5;

/// Gold
///
/// Sponsored Content brand color
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gold: #ffc317;


/// Purple
///
/// Native promo color
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-purple: #655ADE;


/// Light Purple
///
/// Native promo color
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-purple-light: #9081FF;


/// Lightest Gray
///
/// For page backgrounds
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gray-lightest: #f8f8f8;

/// Lighter Gray
///
/// For component background colors
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gray-lighter: #eee;

/// Light Gray
///
/// For borders and rules
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gray-light: #dbdbdb;

/// Gray
///
/// A shade of gray. Dorian Gray.
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gray: #c7c7c7;

/// Dark Gray
///
/// The lightest text should be.
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gray-dark: #999;

/// Darker Gray
///
/// A dark text gray.
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gray-darker: #777;

/// Darkest Gray
///
/// Like my soul.
///
/// @deprecated
///
/// @access private
///
/// @type Color
$color-gray-darkest: #333;
