////
/// Weights
///
/// The available `font-weight`&#39;s for _The Atlantic_
///
/// @source https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping font-weight on MDN
///
/// @since 0.0.1
////

// Font Weights
//
// Single purpose classes to set the font-weight of any element at any breakpoint.
//
// Varying the font-weight of different pieces of text can help create contrast between pieces of information.
// It can help call attention to a piece of content, or help to make a smaller font-size a bit more readable.
// In Elements of Typographic Style, Robert Bringhurst suggests we keep two things in mind when playing with font-weight.
// Use bold weights sparingly. Font-weight should decrease as font-size increases.
//
// <hr>
//
// <p class="sg-system sg-font-normal">Font Weight: 400</p>
// <p class="sg-system sg-font-medium">Font Weight: 500</p>
// <p class="sg-system sg-font-semi-bold">Font Weight: 600</p>
// <p class="sg-system sg-font-bold">Font Weight: 700</p>
// <p class="sg-system sg-font-extra-bold">Font Weight: 800</p>
//
// See: <strong><a href="http://tachyons.io/docs/typography/font-weight/" target="_blank">Tachyons</a></strong>
//

/// Thin font weight
///
/// @type Number
$weight-thin: 100;

/// Extra Light font weight
///
/// @type Number
$weight-extra-light: 200;

/// Light font weight
///
/// @type Number
$weight-light: 300;

/// Normal font weight
///
/// @type Number
$weight-normal: 400;

/// Medium font weight
///
/// @type Number
$weight-medium: 500;

/// Semi Bold font weight
///
/// @type Number
$weight-semi-bold: 600;

/// Bold font weight
///
/// @type Number
$weight-bold: 700;

/// Black font weight
///
/// @type Number
$weight-extra-bold: 800;

/// Black font weight
///
/// @type Number
$weight-black: 900;
