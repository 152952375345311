////
/// Line Height
///
/// @source https://zpl.io/VDJlLrb Zeplin Web
/// @source zpl://screen?pid=58d13c5ccb896e363a5ec56b&sid=58dfda193f0e46964c45e657 Zeplin App
////

// Line Height
//
// line-height is a css property
//
// lead [rhyming with red]:
// Originally a strip of soft metal used for vertical spacing between lines of type. Now meaning the vertical distance from the baseline of one line to the baseline of the next. Also called leading.
//
// "Many people with cognitive disabilities have trouble tracking lines of text when a block of text is single spaced. Providing spacing between 1.5 to 2 allows them to start a new line more easily once they have finished the previous one."
//
// &ndash; WCAG 2.0 Compliance Techniques
//
// Line-height affects how easy it is to read a piece of text, so having a well constructed set of values can help make your text easier to read, increasing the chances that people will read it.
//
// <hr>
//
// <p class="sg-system sg-font-example-content sg-lh-shortest">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
// <p class="sg-system sg-font-example-content sg-lh-short">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
// <p class="sg-system sg-font-example-content sg-lh-medium">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
// <p class="sg-system sg-font-example-content sg-lh-tall">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
//
// See: <strong><a href="http://tachyons.io/docs/typography/line-height/" target="_blank">Tachyons</a></strong>
//

/// The shortest available `line-height`
///
/// @type Number
$lh-shortest: 1;

/// The short `line-height`
///
/// @type Number
$lh-short: 1.125;

/// The medium `line-height`
///
/// @type Number
$lh-medium: 1.25;

/// The medium-tall `line-height`
///
/// @type Number
$lh-medium-tall: 1.4;

/// The tall `line-height`
///
/// @type Number
$lh-tall: 1.5;

/// Line height of 24px
///
/// @type Number
$lh-18: 18px;

/// Line height of 24px
///
/// @type Number
$lh-20: 20px;

/// Line height of 24px
///
/// @type Number
$lh-24: 24px;

/// Line height of 28px
///
/// @type Number
$lh-28: 28px;

/// Line height of 36px
///
/// @type Number
$lh-36: 36px;
