////
/// Sizes
/// Different sizes that aren't spacing and don't directly affect layout i.e. page width
/// @todo deprecate the old values for something more sane
////

/// Eight pixels
///
/// @type Number
$size-8: 8px;

/// Ten pixels
///
/// @type Number
$size-10: 10px;

/// Sixteen pixels
///
/// @type Number
$size-16: 16px;

// Thirty Two pixels
//
// @type Number
$size-32: 32px;

// Thirty Six pixels
//
// @type Number
$size-36: 36px;

// Fourty pixels
//
// @type Number
$size-40: 40px;

/// Fifty six pixels
///
/// @type Number
$size-56: 56px;

/// Sixty pixels
///
/// @type Number
$size-60: 60px;

/// Seventy Two pixels
///
/// @type Number
$size-72: 72px;

/// Eighty pixels
///
/// @type Number
$size-80: 80px;

/// One hundred pixels
///
/// @type Number
$size-100: 100px;



/// Caret
///
/// The size of the caret icons
///
/// @type Number
$size-caret: 6px;

/// Arrow
///
/// The size of the arrows on large screens
///
/// @type Number
$size-arrow: 18px;

/// Follow Icons
///
/// The size of the icons in the follow component.
///
/// @type Number
$size-icon-follow: 20px;

/// Navigation Icons
///
/// The size of the icons in the nav
///
/// @type Number
$size-icon: 16px;

// Video
//
// @type Number
$size-video: 43px;

/// Tap
///
/// Recommended tap size
///
/// @type Number
$size-tap: 48px;

/// Small Logo
///
/// The width of the small logo
///
/// @type Number
$size-logo-s: 76px;

/// Large Logo
///
/// The width of the large logo
///
/// @type Number
$size-logo-l: 82px;

/// Bottom Persistent ad
///
/// The height of the bottom persistent ad
/// on mobile.
///
/// @type Number
$size-bottom-persistent--small: 58px;

/// Bottom Persistent ad
///
/// The height of the bottom persistent ad
/// on medium and larger screens.
///
/// @type Number
$size-bottom-persistent--large: 100px;
