////
/// Height
///
/// The random heights that we use.
/// Syntax: <type>-<element>[-<location>][-<breakpoint>]

/// Book review cover
///
/// @type Number
$height-book-review-cover: 172px;

/// Breaking news close container
///
/// @type Number
$height-breaking-news-close-container: 36px;

/// Footer secondary
///
/// @type Number
$height-footer-secondary: 300px;

/// Homepage featured item
///
/// @type Number
$height-homepage-featured-item: 80px;

/// Homepage filmstrip item
///
/// @type Number
$height-homepage-filmstrip-item: 86px;

/// Sponsored item mobile
///
/// @type Number
$height-sponsored-item-mobile: 163px;

/// Magazine
///
/// @type Number
$height-magazine: 554px;

/// Magazine small
///
/// @type Number
$height-magazine-small: 454px;

/// Magazine medium
///
/// @type Number
$height-magazine-medium: 557px;

/// Magazine large
///
/// @type Number
$height-magazine-large: 906px;

/// Nav list
///
/// @type Number
$height-nav-list: 48px;

/// Popular image
///
/// @type Number
$height-popular-image: 130px;

/// Right rail ad
///
/// @type Number
$height-right-rail-ad: 250px;

/// Section nav container
///
/// @type Number
$height-section-nav-container: 46px;

/// Writers group
///
/// @type Number
$height-writers-group: 224px;

/// Writers list
///
/// @type Number
$height-writers-list: 234px;

/// Writers image
///
/// @type Number
$height-writers-image: 106px;