@import 'space';

////
/// Width
///
/// The random width's that we use.
/// Syntax: <type>-<element>[-<location>][-<breakpoint>]
///
/// @since 0.0.1
////

/// Main max width
///
/// @type Number
$width-main: 960px;

/// Main wide max width
///
/// @type Number
$width-main-wide: 1280px;

/// Content width
///
/// @type Number
$width-content: 672px;

/// Rail width
///
/// @type Number
$width-sidebar: 300px;

/// Article width
///
/// @type Number
$width-article: $width-content + $width-sidebar + $space-64;

/// Section divider fixed width
///
/// @type Number
$width-section-divider: 96px;

/// Bottom persistent ad fixed width
///
/// @type Number
$width-bottom-persistent: 728px;

/// Footer logo width
///
/// @type Number
$width-logo-footer: 98px;

/// Article Share Kit
///
/// @type Number
$width-share-social: 78px;

/// Article photo thumbnail width
///
/// @type Number
$width-small-media-thumb: 80px;

/// Book review
///
/// @type Number
$width-book-review: 280px;

/// Book review cover
///
/// @type Number
$width-book-review-cover: 132px;

/// Dropdown menu
///
/// @type Number
$width-dropdown-menu: 180px;

/// Homepage featured item
/// Also used for nudge
///
/// @type Number
$width-homepage-featured-item: 230px;

/// Ideas author content
///
/// @type Number
$width-ideas-author-content: 334px;

/// Latest video
///
/// @type Number
$width-latest-video: 177px;

/// Most popular photo - small breakpoint
///
/// @type Number
$width-most-popular-photo: 192px;

/// Nav dropdown menu
///
/// @type Number
$width-nav-menu: 210px;

/// Recirc item
/// Also used for nudge
///
/// @type Number
$width-recirc-item: 256px;

/// Search container
///
/// @type Number
$width-search-container: 362px;

/// Section nav
///
/// @type Number
$width-section-nav: 30px;

/// Section nav
///
/// @type Number
$width-section-nav-wide: 60px;

/// Podcasts
///
/// @type Number
$width-podcast-item: 150px;

/// Popular collection
///
/// @type Number
$width-popular-collection: 210px;

/// Recent issues item
///
/// @type Number
$width-recent-issues-item: 206px;

/// Sponsored item mobile
///
/// @type Number
$width-sponsored-item-mobile: 206px;
