////
/// Space
////

// Spacing
//
// Good design is based on math.
// Certain patterns and ratios are so prevalant in nature and music that they can't be denied as elegant design solutions.
// Even in the 18th century, pages in books were designed with ratios.
// In the 21st century, we have gotten away from this on the web, often using <a href="https://css-tricks.com/magic-numbers-in-css/" target="_blank">magic numbers</a> to match a 'spec' that has been produced in a graphics program such as photoshop, illustrator, or sketch.
// While these programs are useful for sketching ideas, they aren't 100% accurate in their reflection of how the web works across device sizes or how things get drawn to the screen.
//
// Hippo features a spacing scale based on powers of two that starts at .5rem (for most devices this will be the equivalent of 8px).
// Since Hippo uses rem units with px as a fallback, if a user has declared a different base font-size for their device, your spacing will scale based on a defined ratio that has stood the test of time.
// As powers of two will always produce integers, there will be no problems with sub pixel rendering across browsers.
// Computers aren't that great at math and so decimals lead to inconsistencies across platforms.
// Inconsistencies should be avoided where possible.
// You'll find that when using a well thought out scale - things just line up.
// It works, with little effort, regardless of your design knowledge or sensibilities.
//
// <hr>
//
// <div class="sg-space-container" style="background-color: #f6f6f6;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-4">4</div>
// </div>
// <div class="sg-space-container" style="background-color: #dcdcdc;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-8">8</div>
// </div>
// <div class="sg-space-container" style="background-color: #c3c3c3;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-12">12</div>
// </div>
// <div class="sg-space-container" style="background-color: #a9a9a9;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-16">16</div>
// </div>
// <div class="sg-space-container" style="background-color: #a9a9a9;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-20">20</div>
// </div>
// <div class="sg-space-container" style="background-color: #909090;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-24">24</div>
// </div>
// <div class="sg-space-container" style="background-color: #777777;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-32">32</div>
// </div>
// <div class="sg-space-container" style="background-color: #5d5d5d;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-48">48</div>
// </div>
// <div class="sg-space-container" style="background-color: #444444;">
//   <div class="sg-square sg-black sg-color-white sg-system sg-space-64">64</div>
// </div>
//
// Styleguide: Layout.Spacing

/// Four pixels of space
///
/// @type Number
$space-4: 4px;

/// Eight pixels of space
///
/// @type Number
$space-8: 8px;

/// Twelve pixels of space
///
/// @type Number
$space-12: 12px;

/// 16 pixels of space
///
/// @type Number
$space-16: 16px;

/// 20 pixels of space
///
/// @type Number
$space-20: 20px;

/// 24 pixels of space
///
/// @type Number
$space-24: 24px;

/// 28 pixels of space
///
/// @type Number
$space-28: 28px;

/// 32 pixels of space
///
/// @type Number
$space-32: 32px;

/// 40 pixels of space
///
/// @type Number
$space-40: 40px;

/// 48 pixels of space
///
/// @type Number
$space-48: 48px;

/// 64 pixels of space
///
/// @type Number
$space-64: 64px;

/// 72 pixels of space
///
/// @type Number
$space-72: 72px;
