////
/// List Reset
////

/// Reset lists to be unstyled
///
/// @example
///   .river {
///     @include list-reset;
///     // ...
///   }
@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}
