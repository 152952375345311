////
/// Fonts
////

/// Garamond font stack
///
/// @type List
$font-garamond: AGaramondPro, 'Adobe Garamond Pro', Garamond, Georgia, serif;

/// Garamond default stack
///
/// @type List
$font-garamond--default: Georgia, serif;

/// Atlantic Serif font stack
///
/// @type List
$font-atlantic: 'Atlantic Serif', 'Atlantic', 'Bodoni', Georgia, serif;

/// Atlantic Serif default stack
///
/// @type List
$font-atlantic--default: 'Bodoni', Georgia, serif;

/// Druk font stack
///
/// @type List
$font-druk: 'Druk', Impact, Arial Narrow, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;

/// Druk default stack
///
/// @type List
$font-druk--default: Impact, Arial Narrow, sans-serif;

/// Graphik font stack
///
/// @type List
$font-graphik: 'Graphik', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;

/// Graphik default stack
///
/// @type List
$font-graphik--default: -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;

/// Noe Text font stack
///
/// @type List
$font-noe: 'Noe Text', Georgia, serif;

/// Noe Text default stack
///
/// @type List
$font-noe--default: Georgia, serif;

/// Lyon Text font stack
///
/// @type List
$font-lyon: 'Lyon Text', Georgia, serif;

/// Lyon Text default stack
///
/// @type List
$font-lyon--default: Georgia, serif;

/// Logic Monospace font stack
///
/// @type List
$font-monospace: 'Logic Monospace', monospace;
