////
/// Text
///
/// The available text sizes
///
/// @since 0.0.1
////

// Type Scale
//
// Often times, websites devote a non-trivial amount of css to setting font-size.
//
// They declare an unnecessary amount of different font-sizes that upon inspection, don't come close to resembling a sane type scale.
// To create and design an easily readable interface, you don't need more than 40 font-sizes.
// A simple ratio-based scale with 8 options should suffice. Using the class extension namespaces you can set the font-size for any particular breakpoint that you desire.
// Don't spend time constantly overriding font-sizes in your css.
//
// <table class="sg-table sg-system sg-full-width">
//   <tr class="sg-border-bottom">
//     <td class="sg-center sg-font-size sg-font-size--8">8</td>
//     <td class="sg-center sg-font-size sg-font-size--10">10</td>
//     <td class="sg-center sg-font-size sg-font-size--12">12</td>
//     <td class="sg-center sg-font-size sg-font-size--14">14</td>
//     <td class="sg-center sg-font-size sg-font-size--16">16</td>
//     <td class="sg-center sg-font-size sg-font-size--18">18</td>
//     <td class="sg-center sg-font-size sg-font-size--20">20</td>
//     <td class="sg-center sg-font-size sg-font-size--24">24</td>
//     <td class="sg-center sg-font-size sg-font-size--28">28</td>
//     <td class="sg-center sg-font-size sg-font-size--32">32</td>
//     <td class="sg-center sg-font-size sg-font-size--40">40</td>
//     <td class="sg-center sg-font-size sg-font-size--48">48</td>
//     <td class="sg-center sg-font-size sg-font-size--64">64</td>
//     <td class="sg-center sg-font-size sg-font-size--72">72</td>
//   </tr>
// </table>
// <table class="sg-table sg-druk sg-full-width">
//   <tr class="sg-border-bottom">
//     <td class="sg-center sg-font-size sg-font-size--8">8</td>
//     <td class="sg-center sg-font-size sg-font-size--10">10</td>
//     <td class="sg-center sg-font-size sg-font-size--12">12</td>
//     <td class="sg-center sg-font-size sg-font-size--14">14</td>
//     <td class="sg-center sg-font-size sg-font-size--16">16</td>
//     <td class="sg-center sg-font-size sg-font-size--18">18</td>
//     <td class="sg-center sg-font-size sg-font-size--20">20</td>
//     <td class="sg-center sg-font-size sg-font-size--24">24</td>
//     <td class="sg-center sg-font-size sg-font-size--28">28</td>
//     <td class="sg-center sg-font-size sg-font-size--32">32</td>
//     <td class="sg-center sg-font-size sg-font-size--40">40</td>
//     <td class="sg-center sg-font-size sg-font-size--48">48</td>
//     <td class="sg-center sg-font-size sg-font-size--64">64</td>
//     <td class="sg-center sg-font-size sg-font-size--72">72</td>
//   </tr>
// </table>
// <table class="sg-table sg-graphik sg-full-width">
//   <tr class="sg-border-bottom">
//     <td class="sg-center sg-font-size sg-font-size--8">8</td>
//     <td class="sg-center sg-font-size sg-font-size--10">10</td>
//     <td class="sg-center sg-font-size sg-font-size--12">12</td>
//     <td class="sg-center sg-font-size sg-font-size--14">14</td>
//     <td class="sg-center sg-font-size sg-font-size--16">16</td>
//     <td class="sg-center sg-font-size sg-font-size--18">18</td>
//     <td class="sg-center sg-font-size sg-font-size--20">20</td>
//     <td class="sg-center sg-font-size sg-font-size--24">24</td>
//     <td class="sg-center sg-font-size sg-font-size--28">28</td>
//     <td class="sg-center sg-font-size sg-font-size--32">32</td>
//     <td class="sg-center sg-font-size sg-font-size--40">40</td>
//     <td class="sg-center sg-font-size sg-font-size--48">48</td>
//     <td class="sg-center sg-font-size sg-font-size--64">64</td>
//     <td class="sg-center sg-font-size sg-font-size--72">72</td>
//   </tr>
// </table>
// <table class="sg-table sg-lyon-text sg-full-width">
//   <tr class="sg-border-bottom">
//     <td class="sg-center sg-font-size sg-font-size--8">8</td>
//     <td class="sg-center sg-font-size sg-font-size--10">10</td>
//     <td class="sg-center sg-font-size sg-font-size--12">12</td>
//     <td class="sg-center sg-font-size sg-font-size--14">14</td>
//     <td class="sg-center sg-font-size sg-font-size--16">16</td>
//     <td class="sg-center sg-font-size sg-font-size--18">18</td>
//     <td class="sg-center sg-font-size sg-font-size--20">20</td>
//     <td class="sg-center sg-font-size sg-font-size--24">24</td>
//     <td class="sg-center sg-font-size sg-font-size--28">28</td>
//     <td class="sg-center sg-font-size sg-font-size--32">32</td>
//     <td class="sg-center sg-font-size sg-font-size--40">40</td>
//     <td class="sg-center sg-font-size sg-font-size--48">48</td>
//     <td class="sg-center sg-font-size sg-font-size--64">64</td>
//     <td class="sg-center sg-font-size sg-font-size--72">72</td>
//   </tr>
// </table>
// <table class="sg-table sg-noe sg-full-width">
//   <tr class="sg-border-bottom">
//     <td class="sg-center sg-font-size sg-font-size--8">8</td>
//     <td class="sg-center sg-font-size sg-font-size--10">10</td>
//     <td class="sg-center sg-font-size sg-font-size--12">12</td>
//     <td class="sg-center sg-font-size sg-font-size--14">14</td>
//     <td class="sg-center sg-font-size sg-font-size--16">16</td>
//     <td class="sg-center sg-font-size sg-font-size--18">18</td>
//     <td class="sg-center sg-font-size sg-font-size--20">20</td>
//     <td class="sg-center sg-font-size sg-font-size--24">24</td>
//     <td class="sg-center sg-font-size sg-font-size--28">28</td>
//     <td class="sg-center sg-font-size sg-font-size--32">32</td>
//     <td class="sg-center sg-font-size sg-font-size--40">40</td>
//     <td class="sg-center sg-font-size sg-font-size--48">48</td>
//     <td class="sg-center sg-font-size sg-font-size--64">64</td>
//     <td class="sg-center sg-font-size sg-font-size--72">72</td>
//   </tr>
// </table>
// <table class="sg-table sg-trebuchet sg-full-width">
//   <tr>
//     <td class="sg-center sg-font-size sg-font-size--8">8</td>
//     <td class="sg-center sg-font-size sg-font-size--10">10</td>
//     <td class="sg-center sg-font-size sg-font-size--12">12</td>
//     <td class="sg-center sg-font-size sg-font-size--14">14</td>
//     <td class="sg-center sg-font-size sg-font-size--16">16</td>
//     <td class="sg-center sg-font-size sg-font-size--18">18</td>
//     <td class="sg-center sg-font-size sg-font-size--20">20</td>
//     <td class="sg-center sg-font-size sg-font-size--24">24</td>
//     <td class="sg-center sg-font-size sg-font-size--28">28</td>
//     <td class="sg-center sg-font-size sg-font-size--32">32</td>
//     <td class="sg-center sg-font-size sg-font-size--40">40</td>
//     <td class="sg-center sg-font-size sg-font-size--48">48</td>
//     <td class="sg-center sg-font-size sg-font-size--64">64</td>
//     <td class="sg-center sg-font-size sg-font-size--72">72</td>
//   </tr>
// </table>
//
// See: <strong><a href="http://tachyons.io/docs/typography/scale/" target="_blank">Tachyons</a></strong>
//

/// Text size at 144px
///
/// @type Number
$text-144: 144px;

/// Text size at 72px
///
/// @type Number
$text-72: 72px;

/// Text size at 64px
///
/// @type Number
$text-64: 64px;

/// Text size at 56px
/// TODO: deprecate this
/// @type Number
$text-56: 56px;

/// Text size at 52px
/// @type Number
$text-52: 52px;

/// Text size at 48px
///
/// @type Number
$text-48: 48px;

/// Text size at 44px
///
/// @type Number
$text-44: 44px;

/// Text size at 42px
///
/// @type Number
$text-42: 42px;

/// Text size at 40px
///
/// @type Number
$text-40: 40px;

/// Text size at 32px
///
/// @type Number
$text-32: 32px;

/// Text size at 28px
///
/// @type Number
$text-28: 28px;

/// Text size at 24px
///
/// @type Number
$text-24: 24px;

// Text size at 22px
//
// @deprecated This should *only* be used for the article dek
// @see https://atlanticmedia.atlassian.net/wiki/spaces/DSO/pages/183402565/Design+Review
//
// @type Number
$text-22: 22px;

/// Text size at 20px
///
/// @type Number
$text-20: 20px;

/// Text size at 18px
///
/// @type Number
$text-18: 18px;

/// Text size at 16px
///
/// @type Number
$text-16: 16px;

/// Text size at 14px
///
/// @type Number
$text-14: 14px;

/// Text size at 12px
///
/// @type Number
$text-12: 12px;

/// Text size at 11px
///
/// @type Number
$text-11: 11px;

/// Text size at 10px
///
/// @type Number
$text-10: 10px;

/// Text size at 8px
/// TODO: Deprecate this maybe?
/// @type Number
$text-8: 8px;
