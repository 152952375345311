////
/// Breakpoints
///
/// The breakpoints of _The Atlantic_
///
/// Syntax: <type>-<size>|-<custom-defition>
///
/// @since 0.0.1
////

/* Gulp b2b must be run in order for changes to this file to be relected in
   breakpoints.json. This task is part of the build and also can be run independently */

/// Reverse XXSmall device breakpoint
///
/// @type Variable
$breakpoint-xxsmall: max-width 374px;

/// Reverse Small device breakpoint
///
/// @type Variable
$breakpoint-xsmall: max-width 575px;

/// Medium mobile breakpoint
///
/// @type Variable
$breakpoint-mobile-medium: min-width 374px;

/// Large mobile breakpoint
///
///@type Variable
$breakpoint-mobile-large: min-width 414px;

/// Small device breakpoint
///
/// @type Variable
$breakpoint-small: min-width 576px;

/// Medium device breakpoint
///
/// @type Variable
$breakpoint-medium: min-width 784px;

/// Large device breakpoint
///
/// @type Variable
$breakpoint-large: min-width 976px;

/// XLarge device breakpoint
///
/// @type Variable
$breakpoint-xlarge: min-width 1200px;

/// XXLarge device breakpoint
///
/// @type Variable
$breakpoint-xxlarge: min-width 1376px;

/// Special breakpoint for middle section of homepage
///
/// @type Variable
$breakpoint-hp-mid: min-width 1100px;

/// Standard device resolution (72 PPI monitor)
///
/// @type Variable
$breakpoint-standard: min-resolution 1dppx;

/// HD device resolution (Older Android)
///
/// @type Variable
$breakpoint-hd: min-resolution 1.5dppx;

/// Retina device resolution (iPhone)
///
/// @type Variable
$breakpoint-retina: min-resolution 2dppx;

/// Legacy landing page large breakpoint
///
/// @type Variable
$breakpoint-legacy-large: min-width 1010px;
